import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Heading,
  IconButton,
  Text,
  Box,
  Image,
  Input,
  useColorModeValue,
  useToast,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';
import zxcvbn from 'zxcvbn';
import { updateUserProfile} from '../api'; 
import ProfileImage from './ProfileImage';

const ProfileSection = ({ userProfile, handleUpdate }) => {
  const [newUsername, setNewUsername] = useState('');
  const [newFullName, setNewFullName] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const toast = useToast();

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setNewUsername(newUsername);
    validateUsername(newUsername);
  };

  const handleFullNameChange = (e) => {
    const newFullName = e.target.value;
    setNewFullName(newFullName);
    validateFullName(newFullName);
  };

  const validateUsername = (username) => {
    if (username.length === 0) {
      setUsernameError('');
    } else if (username.length < 3 || username.length > 20) {
      setUsernameError('username must be between 3 and 20 characters');
    } else {
      setUsernameError('');
    }
  };

  const validateFullName = (fullName) => {
    if (fullName.length === 0) {
      setFullNameError('');
    } else if (fullName.length < 3 || fullName.length > 20) {
      setFullNameError('full name must be between 3 and 20 characters');
    } else {
      setFullNameError('');
    }
  };

  const handleUsernameUpdate = async () => {
    if (!usernameError && newUsername.trim() !== '') {
      try {
        await handleUpdate('username', newUsername);
        setNewUsername('');
        toast({
          title: 'Success',
          description: 'Username updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleFullNameUpdate = () => {
    if (!fullNameError && newFullName.trim() !== '') {
      handleUpdate('fullName', newFullName);
      setNewFullName('');
    }
  };

  return (
    <VStack align='start' spacing='4'>
      <Heading size='md' fontFamily='categoryFont'>
        Profile
      </Heading>
      <VStack align='start' spacing='.5'>
        <Text color='gray.500' fontSize='xs'>
          username - {userProfile.username}
        </Text>
        <Input 
          name="username"
          placeholder="new username" 
          value={newUsername} 
          onChange={handleUsernameChange}
          maxLength={20}
        />
        {usernameError && <Text color='pink.400' fontSize='xs'>{usernameError}</Text>}
      </VStack>
      
      <Button size='sm' onClick={handleUsernameUpdate} isDisabled={!!usernameError || newUsername.trim() === ''}>
        update username
      </Button>

      <VStack align='start' spacing='.5'>
        <Text color='gray.500' fontSize='xs'>
          fullname  - {userProfile.fullName}
        </Text>
        <Input 
          name="fullName"
          placeholder="new fullname" 
          value={newFullName} 
          onChange={handleFullNameChange}
        />
        {fullNameError && <Text color='pink.400' fontSize='xs'>{fullNameError}</Text>}
      </VStack>

      <Button size='sm' onClick={handleFullNameUpdate} isDisabled={!!fullNameError || newFullName.trim() === ''}>
        update fullname
      </Button>
    </VStack>
  );
};

const AccountSection = ({ userProfile, handleUpdate }) => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [currentEmailError, setCurrentEmailError] = useState('');
  const [newEmailError, setNewEmailError] = useState('');
  const toast = useToast();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCurrentEmailChange = (e) => {
    const value = e.target.value;
    setCurrentEmail(value);
    if (value.length === 0) {
      setCurrentEmailError('');
    } else if (!validateEmail(value)) {
      setCurrentEmailError('Enter a valid email address');
    } else {
      setCurrentEmailError('');
    }
  };

  const handleNewEmailChange = (e) => {
    const value = e.target.value;
    setNewEmail(value);
    if (value.length === 0) {
      setNewEmailError('');
    } else if (!validateEmail(value)) {
      setNewEmailError('Enter a valid email address');
    } else {
      setNewEmailError('');
    }
  };

  const handleEmailUpdate = async () => {
    if (currentEmail !== userProfile.email) {
      toast({
        title: 'Error',
        description: 'Current email does not match your account email.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!currentEmailError && !newEmailError && newEmail.trim() !== '') {
      try {
        await handleUpdate('email', { currentEmail, newEmail });
        setCurrentEmail('');
        setNewEmail('');
        toast({
          title: 'Verification Email Sent',
          description: 'Please check your email for a verification link.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <VStack align='start' spacing='4'>
      <Heading size='md' fontFamily='categoryFont'>
        Account
      </Heading>
      <FormControl isInvalid={!!currentEmailError}>
        <FormLabel color='gray.500' fontSize='xs'>current email - {userProfile.email}</FormLabel>
        <Input 
          name="currentEmail"
          placeholder="Enter your current email" 
          value={currentEmail}
          onChange={handleCurrentEmailChange}
        />
        {currentEmailError && (
          <FormErrorMessage>{currentEmailError}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!newEmailError}>
        <FormLabel color='gray.500' fontSize='xs'>new email</FormLabel>
        <Input 
          name="newEmail"
          placeholder="Enter new email" 
          value={newEmail}
          onChange={handleNewEmailChange}
        />
        {newEmailError && (
          <FormErrorMessage>{newEmailError}</FormErrorMessage>
        )}
      </FormControl>
      
      <Button 
        size='sm' 
        onClick={handleEmailUpdate}
        isDisabled={!!currentEmailError || !!newEmailError || currentEmail.trim() === '' || newEmail.trim() === ''}
      >
        Update Email
      </Button>
    </VStack>
  );
};

const PasswordSection = ({ handleUpdate }) => {
  const [passwordData, setPasswordData] = useState({ currentPassword: '', newPassword: '' });
  const [passwordStrength, setPasswordStrength] = useState(null);
  const toast = useToast();

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (name === 'newPassword') {
      const result = zxcvbn(value);
      setPasswordStrength(result);
    }
  };

  const isPasswordWeak = passwordStrength && passwordStrength.score < 2;

  const renderPasswordStrength = () => {
    if (!passwordData.newPassword || !passwordStrength) return null;

    const crackTime = passwordStrength.crack_times_display.offline_slow_hashing_1e4_per_second;
    const score = passwordStrength.score;

    let color;
    switch (score) {
      case 0:
      case 1:
        color = 'red.500';
        break;
      case 2:
        color = 'orange.500';
        break;
      case 3:
        color = 'yellow.500';
        break;
      case 4:
        color = 'green.500';
        break;
      default:
        color = 'gray.500';
    }

    return (
      <Text color={color} fontSize="sm" mt={1} textAlign="left">
        Time to hack: {crackTime}
      </Text>
    );
  };

  const handlePasswordUpdate = async () => {
    if (!passwordData.currentPassword || !passwordData.newPassword) {
      toast({
        title: 'Error',
        description: 'Please enter both current and new password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (isPasswordWeak) {
      toast({
        title: 'Weak Password',
        description: 'Please choose a stronger password',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      await handleUpdate('password', passwordData);
      // Clear the password fields after successful update
      setPasswordData({ currentPassword: '', newPassword: '' });
      setPasswordStrength(null);
      toast({
        title: 'Password Updated',
        description: 'Your password has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'An error occurred while updating your password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <VStack align='start' spacing='4'>
        <Heading size='md' fontFamily='categoryFont'>
          Password
        </Heading>
        <VStack align='start' spacing='.5'>
          <Text color='gray.500' fontSize='xs'>
            current password
          </Text>
          <Input 
            name="currentPassword"
            placeholder="enter current pass" 
            type="password"
            value={passwordData.currentPassword}
            onChange={handlePasswordChange}
            autocomplete="current-password"
          />
        </VStack>
        <FormControl isInvalid={isPasswordWeak}>
          <Text color='gray.500' fontSize='xs'>
            new password
          </Text>
          <Input 
            name="newPassword"
            placeholder="enter new pass" 
            type="password"
            value={passwordData.newPassword}
            onChange={handlePasswordChange}
            autocomplete="new-password"
          />
          <FormHelperText>
            {renderPasswordStrength()}
          </FormHelperText>
        </FormControl>

        <Button 
          size='sm' 
          onClick={handlePasswordUpdate}
          isDisabled={isPasswordWeak || !passwordData.currentPassword || !passwordData.newPassword}
        >
          Update Password
        </Button>
      </VStack>
    </form>
  );
};

const EditProfileModal = ({ 
  userId, 
  userProfile: initialUserProfile, 
  onProfileUpdate,
  profilePicUpdateTrigger,
  setProfilePicUpdateTrigger  ,
  colorMode,
}) => {
  const [userProfile, setUserProfile] = useState(initialUserProfile);
  const { isOpen, onOpen, onClose } = useDisclosure();  
  const [activeSection, setActiveSection] = useState('Profile');
  const toast = useToast();
  const bgColor = useColorModeValue("white", "black");
  const textColor = useColorModeValue("black", "white");
  const borderColor = useColorModeValue("black", "white");

  useEffect(() => {
    setUserProfile(initialUserProfile);
  }, [initialUserProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleUpdateProfilePic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);

      try {
        const updatedUser = await updateUserProfile(userId, formData);
        setUserProfile(prevProfile => ({...prevProfile, ...updatedUser}));
        onProfileUpdate({...updatedUser, profilePicUpdated: true});
        setProfilePicUpdateTrigger(prev => prev + 1);  // Use the shared state updater
        toast({
          title: 'Profile Picture Updated',
          description: 'Your profile picture has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: `${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        console.error('Error updating profile picture:', error);
      }
    }
  };

  const handleUpdate = async (field, data) => {
    try {
      let dataToUpdate;
      switch (field) {
        case 'password':
          dataToUpdate = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword
          };
          break;
        case 'email':
          dataToUpdate = { 
            currentEmail: data.currentEmail, 
            newEmail: data.newEmail 
          };
          break;
        case 'username':
          dataToUpdate = { username: data };
          break;
        case 'fullName':
          dataToUpdate = { [field]: data };
          break;
        default:
          throw new Error('Invalid update field');
      }

      if (field === 'email') {
        const response = await updateUserProfile(userId, dataToUpdate);
        return response; // This will be a message about sending verification email
      } else {
        const updatedUser = await updateUserProfile(userId, dataToUpdate);
        setUserProfile(prevProfile => ({...prevProfile, ...updatedUser.user}));
        onProfileUpdate(updatedUser.user);
        return updatedUser;
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      throw error; // Re-throw the error to be caught in the child component
    }
  };
  
  const renderSection = () => {
    switch (activeSection) {
      case 'Profile':
        return <ProfileSection userProfile={userProfile} handleInputChange={handleInputChange} handleUpdate={handleUpdate} />;
      case 'Account':
        return <AccountSection userProfile={userProfile} handleInputChange={handleInputChange} handleUpdate={handleUpdate} />;
      case 'Password':
        return <PasswordSection handleUpdate={handleUpdate} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Button  m='4' size='xs' variant='outline' onClick={onOpen}>
        Edit Profile
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay 
        bg='none'
        backdropFilter='auto'
        backdropInvert='80%'
        backdropBlur='2px'
        />
        <ModalContent bg={bgColor} borderColor={borderColor} borderWidth="3px" borderRadius='15px'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <HStack align="start" spacing={8}>
                <VStack align='start' spacing='2'>
                  <Box position='relative'>
                  <ProfileImage userId={userId} size={['100px', '150px']} updateTrigger={profilePicUpdateTrigger} />
                    <IconButton
                      aria-label="Edit profile picture"
                      icon={<FaEdit />}
                      position="absolute"
                      bg="pink.400"
                      top={['-8px', '-12px']}
                      right={['-8px', '-12px']}
                      size="sm"
                      _hover={{ color: 'qPurple.500' }}
                      onClick={() => document.getElementById('profile-image-upload').click()}
                    />
                  </Box>
                  
                  <Input
                    type="file"
                    id="profile-image-upload"
                    style={{ display: 'none' }}
                    onChange={handleUpdateProfilePic}
                  />
                 <VStack align='start' paddingTop='20px' spacing='4'>
                  <Button 
                      variant="link" 
                      color={activeSection === 'Profile' ? 'pink.400' : {textColor}}
                      size='sm'
                      textTransform="none"
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => setActiveSection('Profile')}>
                        Profile
                    </Button>
                    <Button 
                      variant="link" 
                      color={activeSection === 'Account' ? 'pink.400' : {textColor}}
                      size='sm'
                      textTransform="none"
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => setActiveSection('Account')}>
                        Account
                    </Button>
                    <Button 
                      variant="link" 
                      color={activeSection === 'Password' ? 'pink.400' : {textColor}}
                      size='sm'
                      textTransform="none"
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => setActiveSection('Password')}>
                      Password
                    </Button>
                 </VStack>
                </VStack>
                {renderSection()}
              </HStack>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfileModal;