import React, {useState, useEffect} from 'react';
import {Heading, HStack, VStack, Box, SimpleGrid, Button, Spinner} from '@chakra-ui/react';
import FilmCard from '../FilmCard';
import ProfileCard from '../ProfileCard';
import {getFilmsByGenres} from '../../api';
const SearchResultsScene = (props) => {
    const {
        results,
        // allResults, 
        setScene,
        setSelectedFilm,
        filterIsLoading,
        searchType,
        selectedGenres,
        setSelectedGenres
        // how do i pass in the selected genres from the top nav?
    } = props;

    return (
        <>
            {filterIsLoading ? (
                    <Spinner p="20" color="qTurquoise.500" size="xl" />
                ) : results?.results?.length === 0 ? (
                    <Box>No results found, try another search</Box>
                ) : (
                    <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={4} pb={20} alignItems="start">
                        {/* {allResults?.map((item) => { */}
                        {results?.map((item) => {
                            if (searchType === 'films') {
                                return (
                                    <FilmCard
                                        key={item.id}
                                        filmId={item.id}
                                        title={item.title}
                                        year={item.release_date}
                                        imagePath={item.backdrop_path || item.poster_path}
                                        avgQRating={item.avgQRating}
                                        genres={item.genres}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                );
                            } else {
                                return (
                                    <ProfileCard
                                        userProfile={item}
                                        key={item.userId}
                                        userId={item.userId}
                                        username={item.username}
                                        fullName={item.fullName}
                                        // dont think profilePic is being used anywhere calling it inside profileCard base
                                        // off the UserID I believe? but is this the best approach?
                                        // profilePic={item.profilePic}
                                        userPlaylistCount={item.userPlaylistCount}
                                        userRatingCount={item.userRatingCount}
                                        size='150px'
                                    />
                                );
                            }
                        })}
                    </SimpleGrid>
                )}
                
            {/* {filterIsLoading ? <Spinner p = '20' color= 'qTurquoise.500' size={'xl'} /> : (
                films?.results?.length === 0 ? (
                    <Box>
                        No results found, try another search
                    </Box>
                ) : (
                    <SimpleGrid columns={[2,6]} spacing={[9,20]} paddingTop={4} >
                        {films?.results?.map((film) => {
                            return (
                                <div key={film.id}>
                                    <FilmCard
                                        filmId={film.id}
                                        title={film.title}
                                        year={film.release_date}
                                        imagePath={film.backdrop_path ? film.backdrop_path : film.poster_path}
                                        avgQRating={film.avgQRating}
                                        genres={film.genres} // Add this line to pass genre information
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            );
                        })}
                    </SimpleGrid>
                )
            )} */}
        </>
    );
};

export default SearchResultsScene;