import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
} from '@chakra-ui/react';
import { loginApi } from '../../api';
import { useNavigate } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const LoginScene = (props) => {

    const {
        setScene,
        setLoggedIn,
        loggedIn,
        setUserId,
    } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const toast = useToast();

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    // Email validation function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isEmailError = email.length > 4 && !isValidEmail(email);
    // const isPasswordError = password.length > 0 && password.length < 6; // Assuming minimum 6 characters for password

    const isMobile = useMedia('(max-width: 767px)');
    const navigate = useNavigate();

    const handleEmailInputChange = (e) => setEmail(e.target.value);
    const handlePasswordInputChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (newPassword === '') {
            setPasswordStrength(null);
        } else {
            const result = zxcvbn(newPassword);
            setPasswordStrength(result);
        }
    };

    const renderPasswordStrength = () => {
        if (!password || !passwordStrength) return null;

        const crackTime = passwordStrength.crack_times_display.offline_slow_hashing_1e4_per_second;
        const score = passwordStrength.score;

        let color;
        switch (score) {
            case 0:
            case 1:
                color = 'red.500';
                break;
            case 2:
                color = 'orange.500';
                break;
            case 3:
                color = 'yellow.500';
                break;
            case 4:
                color = 'green.500';
                break;
            default:
                color = 'gray.500';
        }

        return (
            <Text color={color} fontSize="sm" mt={1} textAlign="left">
                Time to hack: {crackTime}
            </Text>
        );
    };

    //LOGIN
    const handleLogin = async () => {
        try{
            setIsLoading(true);
            const loginResult = await loginApi({ email, password });
            setUserId(loginResult.userId);
            
            localStorage.setItem('userInfo', JSON.stringify({userId: loginResult.userId, timestamp: new Date().getTime()}));

            setIsLoading(false);
            toast({
                title: 'Logged In',
                description: email + " you are logged in",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setLoggedIn(true);
            navigate('/');

        }
        catch (e) {
            setIsLoading(false);
            toast({
                title: 'Login Failure',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log('ERROR logging in to account in LoginScene: ', e);
        }
    }

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p={['','72px']} width='100%' justify= 'center'>
                    {!isMobile ? (
                        <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                                QUARTILE
                        </Heading>
                        ) : null} 
                     {!isMobile ? (
                    <VStack p='15' width='50%' justify= 'center'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> enter a valid email address </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                </FormHelperText>
                        </FormControl>

                        {/* <FormControl>
                            <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                            <Input
                                id='password'
                                type='password'
                                value={password}
                                onChange={handlePasswordInputChange}
                            />
                            <FormHelperText>
                                {renderPasswordStrength()}
                            </FormHelperText>
                        </FormControl> */}

                        <Box p={'15'} paddingTop={'25px'}>
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qTurquoise'
                                width ='120px'
                                // colorScheme='red'
                                onClick={() => {handleLogin()}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Login
                            </Button>
                        </Box>

                        <Box p={'2'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='120px'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box>

                        <Box p={'15'} paddingTop={'25px'}>
                        <Text
                            mt={4}
                            textAlign="center"
                            color="pink.500"
                            cursor="pointer"
                            onClick={() => {setScene('RESET')}}
                        >
                            forgot password?
                        </Text>
                        </Box>
                    </VStack>
                    ) : 
                    <VStack paddingTop='5' width='80%' justify= 'center' spacing='0.25'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> Email is required. </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl >
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                </FormHelperText>
                        </FormControl>

                        <Box width='100%' paddingTop={'8'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qTurquoise'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {handleLogin()}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Login
                            </Button>
                        </Box>

                        <Box width='100%' paddingTop={'10'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box>
                        
                        <Box p={'15'} paddingTop={'25px'}>
                        <Text
                            mt={4}
                            textAlign="center"
                            color="pink.500"
                            cursor="pointer"
                            onClick={() => {setScene('RESET')}}
                        >
                            forgot password?
                        </Text>
                        </Box>

                    </VStack>
                    } 

                </VStack>
            </Center>
        </VStack>
    );
};

export default LoginScene;