import React, { useState } from 'react';
import {
    VStack,
    Text,
    Center,
    Heading,
    useToast,
} from '@chakra-ui/react';

const PassResetVerifyScene = (props) => {

    const {
        setScene,
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const toast = useToast();

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p='72px' width='100%' justify= 'center' spacing={'10'}>

                    <Text   color={'pink.500'} fontFamily={"paragraphFont"}  >
                        Password Must Be Reset From Your Email Account. 
                    </Text>
                    <Text width={'70%'}>
                        If you don't receive an email within 10 minutes, please check your spam folder or contact support.
                    </Text>

                </VStack>
            </Center>
        </VStack>
    );
};

export default PassResetVerifyScene;