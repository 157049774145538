import { Global } from '@emotion/react'

const Fonts = () => (
    <Global
      //   styles={`
      // /* latin */
      //
      // @font-face {
      //   font-family: 'Acier-TextSolid';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: swap;
      //   src: url('./fonts/Acier-TextSolid.ttf') format('truetype');
      //   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      // }
      // /* latin */
      // @font-face {
      //   font-family: 'Ailerons';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: swap;
      //   src: url('./fonts/Ailerons-Regular.otf') format('opentype');
      //   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      // }
      // @font-face {
      //   font-family: 'Arcane Nine';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: swap;
      //   src: url('./fonts/Arcane Nine.otf') format('opentype');
      //   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      // }
      // @font-face {
      //   font-family: 'Modenine';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: swap;
      //   src: url('./fonts/Modenine.otf') format('opentype');
      //   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      // }
      //
      // @font-face {
      //   font-family: 'Orborn';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-display: swap;
      //   src: url('./fonts/Orborn.ttf') format('truetype');
      // }
      // `}
    />
)

export default Fonts