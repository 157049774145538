import React from 'react';
import {
    HStack,
    VStack,
    Button,
    IconButton,
    Heading, 
    Menu,
    MenuButton,
    useColorModeValue,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';

const MobileAltNav = (props) => {

    const {
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        userId,
    } = props;

    const navigate = useNavigate();

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const callLogOut = () => {
        try {
            handleLogout();
            navigate('/');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const onHomeClick = () => {
        navigate('/');
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <MenuItem 
                    letterSpacing='wider' 
                    variant='ghost' 
                    fontFamily="buttonFont" 
                    onClick={() => navigate('/login')}
                >
                    Login
                </MenuItem>
            );
        } else {
            return (
                <MenuItem
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate(`/profile/${userId}`);
                    }}
                >
                    Profile
                </MenuItem>
            );
        }
    }

    return (
        <>
            <HStack marginTop={'12px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading width='200px' colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest">
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16'paddingBottom={'6'}>
                    <Menu isLazy>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                            // icon={<MyCustomIcon/>}
                            variant='ghost'
                        /> 

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: 'white',
                            }} 
                        >
                           {renderLoginButton(loggedIn)}

                            <MenuItem
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {
                                    navigate('/about');
                                }}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>
        </>
    );
};

export default MobileAltNav;