import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    VStack,
} from '@chakra-ui/react';
import LoginScene from './Scenes/LoginScene'
import RegisterScene from './Scenes/RegisterScene'
import VerifyScene from './Scenes/VerifyScene'
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';
import PasswordResetScene from './Scenes/PasswordResetScene';
import PassResetVerifyScene from './Scenes/PassResetVerifyScene';

const LoginPage = (props) => {

    const {
        setLoggedIn,
        loggedIn,
        handleLogout,
        setUserId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [scene, setScene] = useState('LOGIN');
    const isMobile = useMedia('(max-width: 767px)');

    return (
        <VStack >

            {isMobile ? (
                <MobileAltNav
                    setScene={setScene}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}
                    handleLogout={handleLogout}
                    customSwitchChecked={customSwitchChecked}
                    setCustomSwitchChecked={setCustomSwitchChecked}
                    colorMode={colorMode}
                    toggleColorMode={toggleColorMode}
                />
            ) : (
                <AltNav
                    setScene={setScene}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}
                    handleLogout={handleLogout}
                    customSwitchChecked={customSwitchChecked}
                    setCustomSwitchChecked={setCustomSwitchChecked}
                    colorMode={colorMode}
                    toggleColorMode={toggleColorMode}
                />
            )}

            {scene === 'LOGIN' && (
                <LoginScene
                    setScene={setScene}
                    setUserId={setUserId}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}

                />
            )}

            {scene === 'REGISTER' && (
                <RegisterScene
                    setScene={setScene}
                    setUserId={setUserId}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}

                />
            )}

            {scene === 'VERIFY' && (
                <VerifyScene
                    setScene={setScene}
                    setUserId={setUserId}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}

                />
            )}

            {scene === 'RESET' && (
                <PasswordResetScene
                    setScene={setScene}
                    // setPage={setPage}
                    setUserId={setUserId}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}

                />
            )}

            {scene === 'RESET-VERIFY' && (
                <PassResetVerifyScene
                    setScene={setScene}
                    // setPage={setPage}
                    setUserId={setUserId}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}

                />
            )}
        </VStack>
    );
};

export default LoginPage;