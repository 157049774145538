import React, { useEffect, useState } from 'react';
import {
    Text,
    Center,
    useToast,
    VStack
} from '@chakra-ui/react';
import { verifyEmailChangeApi } from '../api';
import { useNavigate } from "react-router-dom";
import '../App.css';
import '../index.css';

const VerifyEmailChangePage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const token = new URL(window.location.href).searchParams.get('token');
  const toast = useToast();

  useEffect(() => {
    const verifyEmailChange = async () => {
      try {
        const response = await verifyEmailChangeApi({ token });
        setSuccess(response.message);
        toast({
          title: 'Email Updated',
          description: "Your email has been successfully updated. wait a sec and you'll be redirected to the home page",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);
      } catch (err) {
        toast({
          title: 'Email Update Failed',
          description: err.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        console.log('ERROR updating email in VerifyEmailChangePage: ', err);
        setError(err.message);
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);
      }
    };

    verifyEmailChange();
  }, []);

  return (
    <div>
      <Center>
        <VStack spacing='20px'pt='20'>
          <Text>
            Email Change Verification Page
          </Text>
          {error && <p>{error}</p>}
          {success && <p>{success}</p>}
        </VStack>
        
      </Center>
    </div>
  );
};

export default VerifyEmailChangePage;