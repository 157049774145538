import { mode } from '@chakra-ui/theme-tools';
import {extendTheme, useColorMode, useColorModeValue, StyleFunctionProps} from '@chakra-ui/react'

// StyleFunctionProps need to use typescript to work.
//  can use layerStyles to accomplish some theming changes instead of direct to component changes.
const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
    // useSystemColorMode: If true, your app will change color mode based on the user's system preferences.
}
// other test code for setting color mode correctly
const styles = {
    global: props => ({
        body: {
            color: mode('#000', 'whiteAlpha.900')(props),
            bg: mode('#FFF', '#000')(props),
            colorScheme: mode('red', 'blue')(props),
        },

    }),
};

const fonts = {
    //qfont working
    qFont: 'Ailerons, sans-serif',
    //category font working
    categoryFont: 'Arcane Nine, sans-serif',
    //number font not working
    numberFont: 'Orborn, sans-serif',

    //paragraph font working
    body: 'Modenine, sans-serif',
    paragraphFont: 'Modenine, sans-serif',

    //button font working
    buttonFont: 'Acier-TextSolid, sans-serif',

};

const components = {
    Button: {
        baseStyle: ({ colorMode }) => ({
            //not sure what colorMode is effecting
            bg: colorMode === 'dark' ? '' : '',
            //color changes the text of button components
            color: colorMode === 'dark' ? 'white' : 'black',
            // colorScheme is
            colorScheme: colorMode === 'dark' ? 'white' : 'red',

            // textColor: colorMode === 'dark' ? 'white' : 'black',
            // borderColor: colorMode === 'dark' ? 'white':'black',
            //formatting for size done elsewhere probably want to comment the below section
            textTransform: 'uppercase',
            fontWeight: 'semibold',
            letterSpacing: '0.02em',
            padding: '4px',
            // borderRadius: '10px',
            fontSize: '12px',
        }),
        variants: {
            'custom-button' : ({ colorMode }) => ({
                // second slot it light mode setting
                bg: colorMode === 'dark' ? 'black' : 'white',
                //color changes the text of button components
                color: colorMode === 'dark' ? 'white' : 'black',
                // colorScheme is
                colorScheme: colorMode === 'dark' ? 'white' : 'red',
                textColor: colorMode === 'dark' ? 'white' : 'red',
            }),
        },

    },

    Heading: {
        variants: {
            'custom-tq': {
                // bg: 'red.400',
                color: '#65C8D0',
                fontWeight: 'lighter',
                fontSize: '5rem',
                letterSpacing:'10px',
                // boxShadow: '0 0 2px 2px #efdfde',
            },

        },
    },

    // rateFilmScene avgRating Bg Color
    Center: {
            variants: {
              'light-dark': ({ colorMode }) => ({
                bg: `${useColorModeValue('white', 'black')(colorMode)}`,
              }),
            },
          },

// Partial checkbox theming should we need it in the future, need to potentially resest default props like example below this one on some components
    // Checkbox: {
    //     baseStyle: ({ colorMode }) => ({
    //       icon: {
    //         color: 'white',
    //       },
    //       control: {
    //         border: '1px',
    //         borderColor: 'gray.300',
    //         borderRadius: 'base',
    //         _disabled: {
    //           borderColor: 'gray.300',
    //           bg: 'gray.200',
    //         },
    //       },
    //       label: {
    //         fontWeight: 'medium',
    //         color: 'gray.900',
    //         fontSize: '10px',
    //       },
    //     }),
    //     variants: {
    //         'mobile': ({ colorMode }) => ({
    //             icon: {
    //             //   color: 'white',
    //             },
    //             control: {
    //             //   border: '1px',
    //             //   borderColor: 'gray.300',
    //             //   borderRadius: 'base',
    //             //   _disabled: {
    //             //     borderColor: 'gray.300',
    //             //     bg: 'gray.200',
    //             //   },
    //             },
    //             label: {
    //             //   fontWeight: 'medium',
    //               color: 'red.900',
    //               fontSize: '10px'
    //             },
    //           }),
    //     },
    // },

};

const colors = {
    qTurquoise: {
        50: '#dffbfd',
        100: '#beebef',
        200: '#9bdce2',
        300: '#76ced5',
        400: '#53c0c8',
        500: '#65C8D0',
        600: '#2a8289',
        700: '#1a5d62',
        800: '#07393c',
        900: '#001517',
    },
    // },
    qPurple: {
        50: '#f3eaff',
        100: '#d7c5f0',
        200: '#bc9ee1',
        300: '#a079d4',
        400: '#8553c6',
        500: '#6c39ac',
        600: '#542c87',
        700: '#3c1f62',
        800: '#24123c',
        900: '#0f041a',
    },
    // want to make a kinda off black like zmshapes site
    qOffBlack: {
        50: '#f2f3f8',
        100: '#d8d8da',
        200: '#bebebf',
        300: '#a4a4a4',
        400: '#89898a',
        500: '#707071',
        600: '#575758',
        700: '#3e3e3f',
        800: '#252527',
        900: '#0c0c12',
  },
};

//not exported
const fontSizes = {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
};

//not exported
const fontWeights = {
    normal: 400,
    medium: 500,
    bold: 700,
};

//not exported
const lineHeights = {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
};

const letterSpacings =  {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.1em",
    widest: "0.2em",
};


// add theme override object to set global button overide etc here.
const theme = extendTheme({
    config,
    fonts,
    styles,
    components,
    colors,
    fontSizes,
    letterSpacings,
});

export default theme;