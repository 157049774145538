import React, { useState, useEffect } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  VStack,
  HStack,
  IconButton,
  Text,
  useColorModeValue,
  Spinner,
  Input,
  useToast,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import { FaCheck, FaPlus } from 'react-icons/fa';
// icons to be put in the popover display to show on the button whether the film is in the playlist or not
// import { CheckIcon, AddIcon } from '@chakra-ui/icons';
import { getPlaylists, updatePlaylist, createPlaylist, getQuartileFilm } from '../api'; // Assuming you have these API methods

const AddToPlaylistPopover = ({ filmId, userId }) => {
  const [playlists, setPlaylists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newPlaylistTitle, setNewPlaylistTitle] = useState('');
  const [qFilmId, setQFilmId] = useState(null);
  const [playlistFilmPresence, setPlaylistFilmPresence] = useState({});
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");

  useEffect(() => {
    const fetchFilmIdAndPlaylists = async () => {
      setIsLoading(true);
      try {
        const filmData = await getQuartileFilm(filmId);
        if (filmData && filmData._id) {
          setQFilmId(filmData._id);
          
          const playlistsData = await getPlaylists(userId);
          setPlaylists(playlistsData.playlists);
  
          // Check if the film is in each playlist
          const presence = {};
          playlistsData.playlists.forEach(playlist => {
            presence[playlist._id] = playlist.playlistFilms.some(film => film._id === filmData._id);
          });
          setPlaylistFilmPresence(presence);
          // console.log('Playlist Film Presence:', presence);
        } else {
          console.error('Film data is null or _id is missing');
          setQFilmId(null);
        }
      } catch (error) {
        console.error('Error fetching film ID or playlists:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchFilmIdAndPlaylists();
  }, [userId, filmId]);

// remove or add film from playlist
  const handleUpdatePlaylist = async (playlistId, playlistTitle) => {
    try {
      if (playlistFilmPresence[playlistId]) {
        // Remove film from playlist
        await updatePlaylist(playlistId, { $pull: { playlistFilms: qFilmId } });
        setPlaylistFilmPresence(prev => ({ ...prev, [playlistId]: false }));
        toast({
          title: 'Film Removed',
          description: `The film has been REMOVED from your ${playlistTitle} playlist`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Add film to playlist
        await updatePlaylist(playlistId, { $push: { playlistFilms: qFilmId } });
        setPlaylistFilmPresence(prev => ({ ...prev, [playlistId]: true }));
        toast({
          title: 'Film Added',
          description: `The film has been ADDED to your ${playlistTitle} playlist`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error updating playlist:', error);
    }
  };

  const handleCreatePlaylist = async () => {
    const newPlaylist = {
      user_id: userId,
      playlistTitle: newPlaylistTitle,
      playlistFilms: [qFilmId],
    };

    try {

      const responseData = await createPlaylist(newPlaylist);
      
      toast({
        title: 'Playlist Created',
        description: `The new ${newPlaylistTitle} playlist has been created and the film added to it.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewPlaylistTitle('');
      const playlistsData = await getPlaylists(userId);
      setPlaylists(playlistsData.playlists);

      // Update playlistFilmPresence for the new playlist
      setPlaylistFilmPresence(prev => ({
        ...prev,
        [responseData.playlist._id]: true // Assuming the API returns the new playlist with its _id
      }));

    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error creating the new playlist: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error creating new playlist:', error);
    }
  };
  

  return (
    <Popover placement='right'>
      <PopoverTrigger>
        <Button>Add to Playlist</Button>
      </PopoverTrigger>
      <PopoverContent 
        bg={bgColor} 
        borderColor='qPurple.400' 
        borderWidth='4px'
        borderRadius='15px'
        boxShadow='none !important'
        width='400px'
        maxHeight='80vh'  // Set a maximum height
      >
        <PopoverArrow bg='qPurple.400' />
        <PopoverCloseButton bg='qPurple.400' m={1}/>
        <PopoverHeader color={textColor}>
          <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'} pb={2}>
            PLAYLISTS
          </Heading>
        </PopoverHeader>
        <PopoverBody maxHeight='calc(80vh - 100px)' overflowY='auto'>
          {isLoading ? (
            <Spinner />
          ) : (
            <VStack align="start" spacing={3}>
              <VStack justify='center' width='100%'>
                <Input
                  placeholder="New Playlist Title"
                  value={newPlaylistTitle}
                  onChange={(e) => setNewPlaylistTitle(e.target.value)}
                />
                <Button
                  onClick={handleCreatePlaylist}
                  disabled={!newPlaylistTitle.trim()}
                >
                  Add To New Playlist
                </Button>
              </VStack>
              
              <Spacer borderBottom='2px' borderColor='qPurple.400'/>
              
              {playlists.map((playlist) => (
                <HStack key={playlist._id} justify="space-between" w="100%">
                  <Text>{playlist.playlistTitle}</Text>
                  <IconButton
                    icon={playlistFilmPresence[playlist._id] ? <FaCheck /> : <FaPlus />}
                    colorScheme={playlistFilmPresence[playlist._id] ? "green" : "qPurple"}
                    onClick={() => handleUpdatePlaylist(playlist._id, playlist.playlistTitle)}
                    aria-label={playlistFilmPresence[playlist._id] ? "Remove from playlist" : "Add to playlist"}
                  />
                </HStack>
              ))}
              <Spacer pb='4'/>
            </VStack>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddToPlaylistPopover;