// Create a new file called FollowButton.jsx
import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';

const FollowButton = ({ isFollowing, handleFollowToggle }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Button
            letterSpacing='wider'
            variant='outline'
            fontFamily="buttonFont"
            // fontSize='14px'
            m='4' 
            size='xs' 
            // colorScheme={isFollowing ? (isHovering ? "red" : "qPurple") : "pink"}
            color={isFollowing ? (isHovering ? "red.400" : "pink.400") : "pink.200"}
            onClick={handleFollowToggle}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {isFollowing 
                ? (isHovering ? "Unfollow" : "Following") 
                : "Follow"}
        </Button>
    );
};

export default FollowButton;