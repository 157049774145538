import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
} from '@chakra-ui/react';
import { initiatePasswordResetApi} from '../../api';

const PasswordResetScene = (props) => {

    const {
        setScene,
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        setUserId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const isEmailError = email.length === 0 ;
    const isMobile = useMedia('(max-width: 767px)');

    const handleEmailInputChange = (e) => setEmail(e.target.value);

    //need to update and fix the logic here to define the logic on how to call the initiate password reset option?
    // const handlePasswordReset = async () => {
    //     try{
    //         setIsLoading(true);
    //         const passwordResetResult = await initiatePasswordResetApi({ email });
    //         setUserId(passwordResetResult.userId);
    //         localStorage.setItem('userInfo', JSON.stringify({userId: passwordResetResult.userId, timestamp: new Date().getTime()}));

    //         setIsLoading(false);
    //         toast({
    //             title: 'Password Reset',
    //             description: email + " Reset Password from Email",
    //             status: 'success',
    //             duration: 9000,
    //             isClosable: true,
    //         })
    //         setScene('RESET-VERIFY')

    //     }
    //     catch (e) {
    //         setIsLoading(false);
    //         toast({
    //             title: 'Password Reset Failure',
    //             description: e.message,
    //             status: 'error',
    //             duration: 9000,
    //             isClosable: true,
    //         })
    //         console.log('ERROR reseting password in PasswordResetScene: ', e);
    //     }
    // }

    const handleInitiatePasswordReset = async () => {
        try {
            setIsLoading(true);
            const passwordResetResult = await initiatePasswordResetApi({ email });
            setIsLoading(false);
            toast({
                title: 'Initiate Password Reset',
                description: email + " Reset Password from Email",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setScene('RESET-VERIFY')
        }
        catch (e) {
            setIsLoading(false);
            toast({
                title: 'Password Reset Failure',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log('ERROR reseting password in PasswordResetScene: ', e);
        }
    }


    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p={['','72px']} width='100%' justify= 'center'>
                    {!isMobile ? (
                        <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                                QUARTILE
                        </Heading>
                        ) : null} 
                     {!isMobile ? (
                    <VStack p='15' width='50%' justify= 'center'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'>  </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                                     Enter the email associated with your account.
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> Email is required. </FormErrorMessage>
                            )}
                        </FormControl>


                        <Box p={'15'} paddingTop={'25px'}>
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                // colorScheme='qTurquoise'
                                color = 'pink.500'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {handleInitiatePasswordReset()}}
                                isDisabled={isEmailError} // Disable the button if there's an email error
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Send Password Link
                            </Button>
                        </Box>

                        {/* <Box p={'2'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='120px'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box> */}
                    </VStack>
                    ) : 
                    <VStack paddingTop='5' width='80%' justify= 'center' spacing='0.25'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'>  </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                                    Enter the email associated with your account.
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> Email is required. </FormErrorMessage>
                            )}
                        </FormControl>

                        <Box width='100%' paddingTop={'8'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                // colorScheme='qTurquoise'
                                color = 'pink.500'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {handleInitiatePasswordReset()}}
                                isDisabled={isEmailError} // Disable the button if there's an email error
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Send Password Link
                            </Button>
                        </Box>

                        {/* <Box width='100%' paddingTop={'10'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box> */}
                    </VStack>
                    } 

                </VStack>
            </Center>
        </VStack>
    );
};

export default PasswordResetScene;