import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const loggedInUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    const time = Date.now();
    if (loggedInUserInfo?.userId && (time - loggedInUserInfo.timestamp < 14400000)) {
      setUserId(loggedInUserInfo.userId);
      setLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, userId, setUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);