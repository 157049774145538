import React, { useState, useEffect } from 'react';
import { Image, Box, useColorModeValue, Spinner } from '@chakra-ui/react';
import { getProfileImageUrl } from '../api';

const ProfileImage = ({ userId, size, updateTrigger = 0  }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const borderColor = useColorModeValue("black", "white");
  const [isLoading, setIsLoading] = useState(true);


  // useEffect(() => {
  //   const loadImage = async () => {
  //     try {
  //       const url = getProfileImageUrl(userId);
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error('Failed to load image');
  //       }
  //       setImageUrl(`${url}?${Date.now()}`);
  //       setImageExists(true);
  //     } catch (error) {
  //       console.error('Error loading profile image:', error);
  //       setImageExists(false);
  //     }
  //   };

  //   loadImage();
  // }, [userId, updateTrigger]);

  // useEffect(() => {
  //   setImageUrl(`/api/auth/${userId}/profile-image`);
  // }, [userId, updateTrigger]);

  // useEffect(() => {
  //   const fullUrl = userId.startsWith('http') 
  //     ? userId 
  //     : `/api/auth/${userId}/profile-image`;
  //   setImageUrl(fullUrl);
  //   console.log('Image URL set to:', fullUrl);  // Log the URL
  // }, [userId, updateTrigger]);

  // useEffect(() => {
  //   const fetchSignedUrl = async () => {
  //     try {
  //       const response = await fetch(`/api/auth/${userId}/profile-image`);
  //       if (!response.ok) throw new Error('Failed to fetch image URL');
  //       const data = await response.json();
  //       setImageUrl(data.url);
  //     } catch (error) {
  //       console.error('Error fetching signed URL:', error);
  //       setImageExists(false);
  //     }
  //   };

  //   fetchSignedUrl();
  // }, [userId, updateTrigger]);

  useEffect(() => {

        // const fetchSignedUrl = async () => {
        //   setIsLoading(true);
        //   try {
        //     const response = await getProfileImageUrl(userId);
        //     if (response.ok) {
        //       const data = await response.json();
        //       if (data && data.url) {
        //         setImageUrl(data.url);
        //         setImageExists(true);
        //       } else {
        //         console.error('No URL received from server');
        //         setImageExists(false);
        //       }
        //     } else {
        //       console.error('Error response from server:', response.status);
        //       setImageExists(false);
        //     }
        //   } catch (error) {
        //     console.error('Error fetching signed URL:', error);
        //     setImageExists(false);
        //   } finally {
        //     setIsLoading(false);
        //   }
        // };

        const fetchSignedUrl = async () => {
          setIsLoading(true);
          try {
            const data = await getProfileImageUrl(userId);
            if (data && data.url) {
              setImageUrl(data.url);
              setImageExists(true);
            } else {
              console.error('No URL received from server');
              setImageExists(false);
            }
          } catch (error) {
            console.error('Error fetching signed URL:', error);
            setImageExists(false);
          } finally {
            setIsLoading(false);
          }
        };
      
        if (userId) {
          fetchSignedUrl();
        } else {
          console.log('No userId provided');
          setIsLoading(false);
        }
    }, [userId, updateTrigger]);

  // const defaultBackgroundColor = '#65C8D0'; // Turquoise color
  const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";


  return (
    <>  
        {isLoading ? (
              <Box width={size} height={size} display="flex" alignItems="center" justifyContent="center">
                  <Spinner size="xl" />
              </Box>
          ) : (
              <Image
                  src={imageExists ? imageUrl : defaultImage}
                  alt="Profile"
                  width={size}
                  height={size}
                  objectFit="cover"
                  className="profilePagePicFormat"
                  style={{ border: `3px solid ${borderColor}` }}
                  onError={() => setImageExists(false)}
              />
          )}
          
        {/* <Image
          src={imageExists ? imageUrl : defaultImage}
          alt="Profile"
          width={size}
          height={size}
          objectFit="cover"
          className="profilePagePicFormat"
          style={{ border: `3px solid ${borderColor}` }}
          onError={() => setImageExists(false)}
        /> */}
    </>
  );
};

export default ProfileImage;