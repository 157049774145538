// ProfileCard.jsx
import React, { useState, useEffect } from 'react';
import { Box, Image, VStack, Heading, useColorModeValue, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getProfileImageUrl } from '../api';

const ProfileCard = (props) => {
    const {
        userProfile,
        userId, 
        username, 
        fullName, 
        profilePic, 
        userPlaylistCount, 
        userRatingCount,
        size,
        updateTrigger = 0,
        forceRefresh = false
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageExists, setImageExists] = useState(true);
    const navigate = useNavigate();
    const borderColor = useColorModeValue("black", "white");
    
    useEffect(() => {
        const fetchSignedUrl = async () => {
          setIsLoading(true);
          try {
            const data = await getProfileImageUrl(userId);
            if (data && data.url) {
              setImageUrl(data.url);
              setImageExists(true);
            } else {
              console.error('No URL received from server');
              setImageExists(false);
            }
          } catch (error) {
            console.error('Error fetching signed URL:', error);
            setImageExists(false);
          } finally {
            setIsLoading(false);
          }
        };
      
        if (userId) {
          fetchSignedUrl();
        } else {
          console.log('No userId provided');
          setIsLoading(false);
        }
    }, [userId, updateTrigger]);
    
    
    // const defaultBackgroundColor = '#65C8D0'; // Turquoise color
    const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    const handleClick = () => {
        if (forceRefresh) {
            window.location.href = `/profile/${userId}`;
        } else {
            navigate(`/profile/${userId}`);
        }
    };

    return (
        <>
            <button onClick={() => {handleClick()}}>
                <VStack class= "containerdiv">
                    {isLoading ? (
                        <Box width={size} height={size} display="flex" alignItems="center" justifyContent="center">
                            <Spinner size="xl" />
                        </Box>
                    ) : (
                        <Image
                            src={imageExists ? imageUrl : defaultImage}
                            alt="Profile"
                            width={size}
                            height={size}
                            objectFit="cover"
                            className="profilePagePicFormat"
                            style={{ border: `3px solid ${borderColor}` }}
                            onError={() => setImageExists(false)}
                        />
                    )}
                    
                    <Heading class = "profileCardUsernameFormat" letterSpacing={'wide'} >
                        @{username}
                    </Heading>
                    <Heading class={'profileCardFullNameFormat'} >
                        {fullName}
                    </Heading>
                    <Heading class={'profileCardPlaylistCountFormat'}>
                        {userPlaylistCount} Playlists
                    </Heading>
                    
                </VStack>
            </button>
        </>
    )
}

export default ProfileCard;
