import React, { useEffect, useState } from 'react';
import { useMedia, } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
    Text,
    useColorMode,
    InputGroup,
    InputRightElement,
    IconButton,
} from '@chakra-ui/react';
import { passwordResetApi } from '../api';
import { useNavigate, useLocation } from "react-router-dom";
import '../App.css';
import '../index.css';
import zxcvbn from 'zxcvbn';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import HomePage from './HomePage';


const PassResetPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);    
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const token = new URLSearchParams(location.search).get('token');
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const isMobile = useMedia('(max-width: 767px)');
    const isEmailError = email?.length === 0;
    const isPasswordWeak = passwordStrength && passwordStrength.score < 2;
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleEmailInputChange = (e) => setEmail(e.target.value);
    const handlePasswordInputChange = (e) => {
        const newPass = e.target.value;
        setPassword(newPass);
        setPasswordStrength(zxcvbn(newPass));
    };

    const renderPasswordStrength = () => {
        if (!newPassword || !passwordStrength) return null;

        const crackTime = passwordStrength.crack_times_display.offline_slow_hashing_1e4_per_second;
        const score = passwordStrength.score;

        let color;
        switch (score) {
            case 0:
            case 1:
                color = 'red.500';
                break;
            case 2:
                color = 'orange.500';
                break;
            case 3:
                color = 'yellow.500';
                break;
            case 4:
                color = 'green.500';
                break;
            default:
                color = 'gray.500';
        }

        return (
            <Text color={color} fontSize="sm" mt={1} textAlign="left">
                Time to hack: {crackTime}
            </Text>
        );
    };
  // useEffect(() => {
  //   const verifyEmail = async () => {
  //     try {
  //       const response = await verifyApi({ token, email, userId});
  //       // setSuccess(response.message);
  //       setEmail(response.email);
  //       setUserId(response.userId);
  //       localStorage.setItem('userInfo', JSON.stringify({userId: response.userId, timestamp: new Date().getTime()}));
  //       toast({
  //         title: 'Account Verified',
  //         description: email + " Account Verified " ,
  //         status: 'success',
  //         duration: 9000,
  //         isClosable: true,
  //     })
  //     setLoggedIn(true)
  //     setTimeout(() => {
  //       navigate('/', { replace: true });
  //     }, 3000);

  //     } catch (err) {
  //         toast({
  //           title: 'Verify Failure',
  //           description: err.message,
  //           status: 'error',
  //           duration: 9000,
  //           isClosable: true,
  //       })
  //       console.log('ERROR Verifying account in VerifyPage: ', err);
  //         // setError(err.message);
  //         setTimeout(() => {
  //           navigate('/', { replace: true });
  //         }, 3000);
  //     }
  //   };

  //   verifyEmail();
  // }, []);


  const handlePasswordReset = async () => {
    if (isPasswordWeak) {
        toast({
            title: 'Weak Password',
            description: "Please choose a stronger password",
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
        return;
    }

    try {
        setIsLoading(true);
        const passwordResetResult = await passwordResetApi({ email, token, newPassword });
        setUserId(passwordResetResult.userId);
        localStorage.setItem('userInfo', JSON.stringify({userId: passwordResetResult.userId, timestamp: new Date().getTime()}));
        
        setIsLoading(false);
        toast({
            title: 'Password Reset Confirmed',
            description: email + " password reset successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
        });
        navigate('/', { replace: true });
    }
    catch (e) {
        setIsLoading(false);
        toast({
            title: 'Password Reset Failure',
            description: e.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
        console.log('ERROR resetting password in PasswordResetScene: ', e);
    }
};

  return (
        <VStack  width ="100%" align='stretch'>
        <Center>
            <VStack p={['','72px']} width='100%' justify= 'center'>
                {!isMobile ? (
                    <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                            QUARTILE
                    </Heading>
                    ) : null} 
                {!isMobile ? (
                <VStack p='15' width='50%' justify= 'center'>
                    <FormControl isInvalid={isEmailError}>
                        <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                        <Input
                            id='email'
                            type='email'
                            value={email}
                            onChange={handleEmailInputChange}
                        />
                        {!isEmailError ? (
                            <FormHelperText>
                                Enter the email associated with your account.
                            </FormHelperText>
                        ) : (
                            <FormErrorMessage> Email is required. </FormErrorMessage>
                        )}
                    </FormControl>

                    {/* <FormControl isInvalid={isPasswordWeak}>
                            <FormLabel letterSpacing='wider' htmlFor='password'>New Password</FormLabel>
                            <Input
                                id='password'
                                type='password'
                                value={newPassword}
                                onChange={handlePasswordInputChange}
                            />
                            {renderPasswordStrength()}
                            {isPasswordWeak && (
                                <FormErrorMessage>Please choose a stronger password.</FormErrorMessage>
                            )}
                    </FormControl> */}

                    <FormControl isInvalid={isPasswordWeak}>
                        <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                        <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                    {isPasswordWeak && (
                                <FormErrorMessage>Please choose a stronger password.</FormErrorMessage>
                            )}
                                </FormHelperText>
                        </FormControl>

                        <Box p={'15'} paddingTop={'25px'} width='100%'>
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                color='pink.500'
                                width='100%'
                                onClick={handlePasswordReset}
                                isDisabled={isEmailError || isPasswordWeak || !email || !newPassword}
                            >
                                Reset Password
                            </Button>
                        </Box>
                </VStack>
                ) : 
                <VStack paddingTop='16' width='80%' justify= 'center' spacing='0.25'>
                    <FormControl isInvalid={isEmailError}>
                        <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                        <Input
                            id='email'
                            type='email'
                            value={email}
                            onChange={handleEmailInputChange}
                        />
                        {!isEmailError ? (
                            <FormHelperText>
                                Enter the email associated with your account.
                            </FormHelperText>
                        ) : (
                            <FormErrorMessage> Email is required. </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl isInvalid={isPasswordWeak}>
                        <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                        <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                    {isPasswordWeak && (
                                <FormErrorMessage>Please choose a stronger password.</FormErrorMessage>
                            )}
                                </FormHelperText>
                        </FormControl>

                        <Box p={'15'} paddingTop={'25px'} width='100%'>
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                color='pink.500'
                                width='100%'
                                onClick={handlePasswordReset}
                                isDisabled={isEmailError || isPasswordWeak || !email || !newPassword}
                            >
                                Reset Password
                            </Button>
                        </Box>
                </VStack>
                } 

            </VStack>
        </Center>
    </VStack>
    );
};

export default PassResetPage;