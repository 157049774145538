import React, { useEffect, useState } from 'react';
import {
    Text,
    useColorMode,
    Center,
    useToast,
} from '@chakra-ui/react';
import { verifyApi } from '../api';
import { useNavigate} from "react-router-dom";
import '../App.css';
import '../index.css';
import HomePage from './HomePage';


const VerifyPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);    
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const token = new URL(window.location.href).searchParams.get('token');
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await verifyApi({ token});
        // setSuccess(response.message);
        setEmail(response.email);
        setUserId(response.userId);
        localStorage.setItem('userInfo', JSON.stringify({userId: response.userId, timestamp: new Date().getTime()}));
        toast({
          title: 'Account Verified',
          description: email + " Account Verified " ,
          status: 'success',
          duration: 9000,
          isClosable: true,
      })
      setLoggedIn(true)
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 3000);

      } catch (err) {
          toast({
            title: 'Verify Failure',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
        })
        console.log('ERROR Verifying account in VerifyPage: ', err);
          // setError(err.message);
          setTimeout(() => {
            navigate('/', { replace: true });
          }, 3000);
      }
    };

    verifyEmail();
  }, []);


  return (
    <div>
      <Center>
        <Text>
        Email Verification page, wait a sec and you'll be redirected to the home page
        </Text>
        {error && <p>{error}</p>}
        {success && <p>{success}</p>}
      </Center>
        
    </div>
  );
};

export default VerifyPage;
