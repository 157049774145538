import React, { Component } from "react";
import {
    HStack,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    Center,
    useColorModeValue,
    SimpleGrid,
} from '@chakra-ui/react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrowWhite from '../rightArrowWhite.png'
import leftArrowWhite from '../leftArrowWhite.png'
import rightArrowBlack from '../rightArrowBlack.png'
import leftArrowBlack from '../leftArrowBlack.png'

const PrevArrow = ({ onClick, currentSlide }) => {
    if (currentSlide === 0) {
      return null;
    }
    const prevArrowImage = useColorModeValue(leftArrowBlack, leftArrowWhite);

    return (
        <button onClick={onClick} style={{ position: 'absolute', right:0, top: '50%', transform: 'translateY(-20%) translateX(-365%)'}}>
        <img src={prevArrowImage} alt="Next Arrow Icon" style={{ width: '70px', height: '70px' }} />
      </button>
    );
  };
  
const NextArrow = ({ onClick, currentSlide }) => {
    if (currentSlide === 4) {
        return null;
      }

    const nextArrowImage = useColorModeValue(rightArrowBlack, rightArrowWhite);

    return (
      <button onClick={onClick} style={{ position: 'absolute', right: -50, top: '50%', transform: 'translateY(-20%)' }}>
        <img src={nextArrowImage} alt="Next Arrow Icon" style={{ width: '70px', height: '70px' }} />
      </button>
    );
  };
  
  class RateCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0
        };
    }

    render() {    
    // this slider const is used to just create custom buttons and we set them displaywhereever we want
    // const slider = React.useRef(null);
    // const [currentSlide, setCurrentSlide] = React.useState(0);
    
    const { 
        qRating, 
        plotRating, 
        actingRating, 
        cinematographyRating, 
        noveltyRating, 
        endingRating,
        setQRating,
        setPlotRating,
        setActingRating,
        setCinematographyRating,
        setNoveltyRating,
        setEndingRating, 
        isDocumentary,
    } = this.props;
    
        const settings = {
        className: 'center',
        dots: true,
        // fade: true,
        // lazyLoad: true,
        infinite: false,
        speed: 500,
        // autoplay: true,
        // autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <NextArrow currentSlide={this.state.currentSlide} />,
        prevArrow: <PrevArrow currentSlide={this.state.currentSlide} />,
        afterChange: current => this.setState({ currentSlide: current }),
        };
    
        return (
        <div style={{ width: '70%', marginTop: '30px' }}>
            <HStack justify={'space-between'}>
            </HStack>
            
            <Slider  {...settings}>
            <div align='center'>
                    <VStack align='center' width='200px'>
                        <Heading mb='-1'letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>PLOT</Heading>
                        {/* <Text pb='1'fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    Select A Number
                        </Text> */}
                        <SimpleGrid  columns={2}>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={plotRating === 4 ? 'qPurple.500' : ''}
                                onClick={() => {setPlotRating(4)}}
                            >
                                4
                            </Button>
                            
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={plotRating === 3 ? 'qPurple.500' : ''}
                                onClick={() => {setPlotRating(3)}}
                            >
                                3
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={plotRating === 2 ? 'qPurple.500' : ''}
                                onClick={() => {setPlotRating(2)}}
                            >
                                2
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={plotRating === 1 ? 'qPurple.500' : ''}
                                onClick={() => {setPlotRating(1)}}
                            >
                                1
                            </Button>
                        </SimpleGrid>
                    </VStack>
            </div>
            <div align='center'>
                    <VStack align='center' width='200px'>
                        <Heading  letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>
                            {isDocumentary ? 'NARRATION' : 'ACTING'} 
                        </Heading>
                        <SimpleGrid  columns={2}>
                         <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={actingRating === 4 ? 'qPurple.500' : ''}
                                onClick={() => {setActingRating(4)}}
                            >
                                4
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={actingRating === 3 ? 'qPurple.500' : ''}
                                onClick={() => {setActingRating(3)}}
                            >
                                3
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={actingRating === 2 ? 'qPurple.500' : ''}
                                onClick={() => {setActingRating(2)}}
                            >
                                2
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={actingRating === 1 ? 'qPurple.500' : ''}
                                onClick={() => {setActingRating(1)}}
                            >
                                1
                            </Button>
                        </SimpleGrid>
                    </VStack>
            </div>
            <div align='center'>
                    <VStack align='center' width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Cinematography</Heading>
                        <SimpleGrid  columns={2}>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={cinematographyRating === 4 ? 'qPurple.500' : ''}
                                onClick={() => {setCinematographyRating(4)}}
                            >
                                4
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={cinematographyRating === 3 ? 'qPurple.500' : ''}
                                onClick={() => {setCinematographyRating(3)}}
                            >
                                3
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={cinematographyRating === 2 ? 'qPurple.500' : ''}
                                onClick={() => {setCinematographyRating(2)}}
                            >
                                2
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={cinematographyRating === 1 ? 'qPurple.500' : ''}
                                onClick={() => {setCinematographyRating(1)}}
                            >
                                1
                            </Button>
                        </SimpleGrid>
                    </VStack>
            </div>
            <div align='center'>
                    <VStack align='center' width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Novelty</Heading>
                        <SimpleGrid  columns={2}>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={noveltyRating === 4 ? 'qPurple.500' : ''}
                                onClick={() => {setNoveltyRating(4)}}
                            >
                                4
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={noveltyRating === 3 ? 'qPurple.500' : ''}
                                onClick={() => {setNoveltyRating(3)}}
                            >
                                3
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={noveltyRating === 2 ? 'qPurple.500' : ''}
                                onClick={() => {setNoveltyRating(2)}}
                            >
                                2
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={noveltyRating === 1 ? 'qPurple.500' : ''}
                                onClick={() => {setNoveltyRating(1)}}
                            >
                                1
                            </Button>
                        </SimpleGrid>
                    </VStack>
            </div>
            <div align='center'>
                    <VStack align='center' width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Ending</Heading>
                        <SimpleGrid  columns={2}>
                             {/*will need to transfer this variant across to every value*/}
                            {/*think we need to right a conditional statement for the background being black/white in dark light mode here if the
                            value is not selected*/}
                            <Button
                                height='80px'
                                // variant={'custom-button'}
                                width='80px'
                                border='2px'
                                background={endingRating === 4 ? 'qPurple.500' : ''}
                                onClick={() => {setEndingRating(4)}}
                            >
                                4
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={endingRating === 3 ? 'qPurple.500' : ''}
                                onClick={() => {setEndingRating(3)}}
                            >
                                3
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={endingRating === 2 ? 'qPurple.500' : ''}
                                onClick={() => {setEndingRating(2)}}
                            >
                                2
                            </Button>
                            <Button
                                height='80px'
                                width='80px'
                                border='2px'
                                background={endingRating === 1 ? 'qPurple.500' : ''}
                                onClick={() => {setEndingRating(1)}}
                            >
                                1
                            </Button>
                        </SimpleGrid>
                    </VStack>
            </div>
            </Slider>
            

        </div>
        );
    };
};
  
  export default RateCarousel;
