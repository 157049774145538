import React, {useState, useEffect} from 'react';
import { useMedia } from 'react-use';
import {
    Heading,
    Text,
    Box,
    SimpleGrid,
    Spinner,
    Spacer,
    VStack,
    Container,
    Button,
    useToast,
} from '@chakra-ui/react';

import FilmCard from '../FilmCard';
// can trim these api calls after db ratingcount and playlistcount is working in production
import { searchTmdbFilmById, updateAllFilmRatingCounts, updateAllUserPlaylistCounts, updateAllUserRatingCounts, updateAllFilmGenres } from '../../api';


const HomeScene = (props) => {
    const { setScene, setSelectedFilm } = props;
    const [featuredFilmsArray, setFeaturedFilmsArray] = useState([])
    const [featureIsLoading, setFeatureIsLoading] = useState(true);
    // deleter these after db ratingcount is working
    const [isUpdatingFilmRatingCounts, setIsUpdatingFilmRatingCounts] = useState(false);
    const [isUpdatingUserRatingCounts, setIsUpdatingUserRatingCounts] = useState(false);
    const [isUpdatingUserPlaylistCounts, setIsUpdatingUserPlaylistCounts] = useState(false);
    const [isUpdatingFilmGenres, setIsUpdatingFilmGenres] = useState(false);
    const toast = useToast();

    const handleUpdateFilmRatingCounts = async () => {
        setIsUpdatingFilmRatingCounts(true);
        try {
            const result = await updateAllFilmRatingCounts();
            toast({
                title: 'Success',
                description: result.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update rating counts',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmRatingCounts(false);
        }
    };

    const handleUpdateUserRatingCounts = async () => {
        setIsUpdatingUserRatingCounts(true);
        try {
            await updateAllUserRatingCounts();
            toast({
                title: "Success",
                description: "All user rating counts updated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating user rating counts:', error);
            toast({
                title: "Error",
                description: "Failed to update user rating counts",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingUserRatingCounts(false);
        }
    };

    const handleUpdateUserPlaylistCounts = async () => {
        setIsUpdatingUserPlaylistCounts(true);
        try {
            const result = await updateAllUserPlaylistCounts();
            console.log('Update result:', result); // Add this line for debugging
            toast({
                title: 'Success',
                description: `${result.updatedUsers} user playlist counts updated successfully`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating playlist counts:', error); // Add this line for debugging
            toast({
                title: 'Error',
                description: error.message || 'Failed to update playlist counts',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingUserPlaylistCounts(false);
        }
    };

    const handleUpdateAllFilmGenres = async () => {
        setIsUpdatingFilmGenres(true);
        try {
            await updateAllFilmGenres();
            toast({
                title: "Success",
                description: "All film genres have been updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update film genres.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmGenres(false);
        }
    };

    //Reset These to Different Feature film ID's for an update to Front Page
    // in order to find the Id go to API and use the Search-movie route with the name of the movie then grab the id to be manually update
    // in our own code below

    // Bullet Train
    const featuredFilmId0 = '718930';
    // Captain Fantastic
    const featuredFilmId1 = '334533';
    // Love Actually
    const featuredFilmId2 = '508';
    // Amsterdam
    const featuredFilmId3 = '664469';
    const isMobile = useMedia('(max-width: 767px)');

    const getFilm = async (featuredFilmId) => {
        try{
            const filmIdResponse = await searchTmdbFilmById(featuredFilmId);
            return filmIdResponse;
        }
        catch (error) {
            console.log(error + "error in feature film call")
        };
    };

    useEffect(() => {
        let isMounted = true;
      
        const loadFeaturedFilms = async () => {
          try {
            const tempFilmsArray = [];
            const filmIds = [featuredFilmId0, featuredFilmId1, featuredFilmId2, featuredFilmId3];
      
            for (let id of filmIds) {
              const film = await getFilm(id);
              if (isMounted) {
                tempFilmsArray.push(film);
              }
            }
      
            if (isMounted) {
              setFeaturedFilmsArray(tempFilmsArray);
              setFeatureIsLoading(false);
            }
          } catch (error) {
            console.error("Error loading featured films:", error);
            if (isMounted) {
              setFeatureIsLoading(false);
            }
          }
        };
      
        loadFeaturedFilms();
      
        return () => {
          isMounted = false;
        };
      }, []);

    // useEffect(async () => {
    //     const tempFilmsArray = [];

    //     const film0 = await getFilm(featuredFilmId0);
    //     tempFilmsArray.push(film0);

    //     const film1 = await getFilm(featuredFilmId1);
    //     tempFilmsArray.push(film1);

    //     const film2 = await getFilm(featuredFilmId2);
    //     tempFilmsArray.push(film2);

    //     const film3 = await getFilm(featuredFilmId3);
    //     tempFilmsArray.push(film3);

    //     setFeaturedFilmsArray(tempFilmsArray);

    //     setFeatureIsLoading(false);

    // }, [])

    return (
        <>
            {!isMobile ? (
                <> 
                <Heading className='shimmer' colorScheme='qTurquoise' variant='custom-tq' fontFamily="qFont" fontSize='8xl'>
                QUARTILE
                </Heading>
                <Heading fontFamily="categoryFont" fontSize={'md'} letterSpacing="widest" color='pink.400'>
                Film Rating System
                </Heading>
            </>
            ) : null} 

            <Spacer></Spacer>

            {!isMobile ? (
                <> 
                <Container  paddingTop='20'  maxW= 'container.xl'>
                    <Heading align='left' fontFamily="categoryFont" paddingTop='6' fontSize='sm' fontWeight='lighest' letterSpacing="0.4em" marginBottom= '-8'>
                            Featured Films
                    </Heading>
                </Container>
            </>
            ) : 
                <Container   paddingLeft='12' paddingTop='3' maxW= 'container.xl'>
                    {/* <Heading align='left' fontFamily="categoryFont" fontSize='xs' fontWeight='lighest' letterSpacing="0.4em" >
                            Films
                    </Heading> */}
                    <Heading align='left' fontFamily="categoryFont" fontSize='xs' fontWeight='lighest' letterSpacing="0.4em" marginBottom='-4' >
                            Featured
                    </Heading>
                </Container>
            } 

            {/* delete this button after the db ratingcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateFilmRatingCounts}
                    isLoading={isUpdatingFilmRatingCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Rating Counts
                </Button>
                {isUpdatingFilmRatingCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating rating counts...</Text>
                    </Box>
                )}
            </Box> */}

            {/* delete this button after the db user ratingcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateUserRatingCounts}
                    isLoading={isUpdatingUserRatingCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Update All User Rating Counts
                </Button>
                {isUpdatingUserRatingCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating user rating counts...</Text>
                    </Box>
                )}
            </Box> */}

            {/* delete this button after the db playlistcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateUserPlaylistCounts}
                    isLoading={isUpdatingUserPlaylistCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Update All User Playlist Counts
                </Button>
                {isUpdatingUserPlaylistCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating user playlist counts...</Text>
                    </Box>
                )}
            </Box>

            <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateAllFilmGenres}
                    isLoading={isUpdatingFilmGenres}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Genres
                </Button>
                {isUpdatingFilmGenres && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating film genres...</Text>
                    </Box>
                )}
            </Box> */}
                
            { featureIsLoading ? < Spinner p = '20' color= 'qTurquoise.500' size={'xl'}/> : (
                <SimpleGrid  className='fade-in' columns={[2, 4]} spacing={[4,10]} >
                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[0]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[0]?.release_date}
                                    imagePath={featuredFilmsArray[0]?.backdrop_path ? featuredFilmsArray[0]?.backdrop_path : featuredFilmsArray[0]?.poster_path}
                                    filmId={featuredFilmsArray[0]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[1]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[1]?.release_date}
                                    imagePath={featuredFilmsArray[1]?.backdrop_path ? featuredFilmsArray[1]?.backdrop_path : featuredFilmsArray[1]?.poster_path}
                                    filmId={featuredFilmsArray[1]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[2]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[2]?.release_date}
                                    imagePath={featuredFilmsArray[2]?.backdrop_path ? featuredFilmsArray[2]?.backdrop_path : featuredFilmsArray[2]?.poster_path}
                                    filmId={featuredFilmsArray[2]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                             <FilmCard
                                    title={featuredFilmsArray[3]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[3]?.release_date}
                                    imagePath={featuredFilmsArray[3]?.backdrop_path ? featuredFilmsArray[3]?.backdrop_path : featuredFilmsArray[3]?.poster_path}
                                    filmId={featuredFilmsArray[3]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>


                </SimpleGrid>

            )}



        </>
    );
};

export default HomeScene;