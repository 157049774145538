import React from 'react';
import {
    VStack,
    Text,
    Center,
} from '@chakra-ui/react';

const VerifyScene = () => {
    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p='72px' width='100%' justify= 'center'   >
                    <Text   variant='' fontFamily={"paragraphFont"}  >
                        Verify Your Email To Login In 
                    </Text>

                </VStack>
            </Center>
        </VStack>
    );
};

export default VerifyScene;