import React from 'react'
import { HStack, VStack, Button, Input, IconButton, Switch, FormControl,Image, Box, Flex ,Spacer, useColorMode, useColorModeValue } from '@chakra-ui/react';
const CustomSwitch = ({ isChecked, handleSwitch, onColor = 'rgba(150, 54, 148, 1)', offColor }) => {

    return (
        <div display= 'block'>
            <input
                className="switch-checkbox"
                id="switch-checkbox"
                checked={isChecked}
                onChange={handleSwitch}
                type="checkbox"

            />
            <label
                htmlFor='switch-checkbox'
                className="switch-label"
                onBgColor={onColor}
                offBgColor={offColor}
                style={{ backgroundColor: isChecked ? onColor : offColor }}
            >
                <span
                    className="switch-button"
                    style={{ backgroundColor: isChecked ? "white" : "black" }}
                    // style={{ backgroundColor: isChecked ? offColor : onColor}}
                />
            </label>
        </div>
    )
}

export default CustomSwitch