import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
    VStack,
    useToast,
} from '@chakra-ui/react';
import TopNav from './TopNav';
import MobileTopNav from './MobileTopNav';
import HomeScene from './Scenes/HomeScene';
import SearchResultsScene from "./Scenes/SearchResultsScene";
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';

const HomePage = (props) => {

    const {
        setLoggedIn,
        loggedIn,
        handleLogout,
        userId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const { topcategory, genre, searchresults } = useParams();
    const [scene, setScene] = useState('HOME');
    const [prevScene, setPrevScene] = useState('');
    const [searchFilmsResponse, setSearchFilmsResponse] = useState([]);
    const [allResults, setAllResults] = useState([]);
    const [searchType, setSearchType] = useState('films');
    const [selectedFilm, setSelectedFilm] = useState([]);
    const [filterIsLoading, setFilterIsLoading] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [toastShown, setToastShown] = useState(false);
    const toast = useToast();

    useEffect(() => {
      const hasShownBetaToast = localStorage.getItem('betaToastShown');
      if (!hasShownBetaToast) {
          toast({
              title: 'Thanks For Being a Beta User',
              description:
                  "Create a Quartile account to rate films, make your own playlists, and follow friends! Think spotify but for movies. To learn more about how our creative rating system works, visit the about page. We appreciate any feedback you have about the site or ideas for new features you would like to see. Contact us at info@quartilefilm.io.",
              status: 'warning',
              duration: 55000,
              position: isMobile ? 'top' : 'bottom-left',
              isClosable: true,
              onCloseComplete: () => {
                  localStorage.setItem('betaToastShown', 'true');
              },
          });
      }
  }, [toast]);
  
  //   useEffect(() => {
  //     toast({
  //         title: 'Thanks For Being a Beta User',
  //         description:
  //             " Create a Quartile account to rate films, make your own playlists, and follow friends! Think spotify but for movies. To learn more about how our creative rating system works, visit the about page. We appreciate any feedback you have about the site or ideas for new features you would like to see. Contact us at info@quartilefilm.io.",
  //         status: 'warning',
  //         duration: 55000,
  //         position: isMobile ? 'top' : 'bottom-left',
  //         isClosable: true,
  //     });
  // }, [])

    // Mobile vs Web Nav Bar Rendering
    const isMobile = useMedia('(max-width: 767px)');

    const renderTopNav = (scene) => {
      if (isMobile) {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <MobileTopNav
              setScene={setScene}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId}
              setSearchType={setSearchType}
              searchType={searchType}
            />
          );
        } else {
          return (
            <MobileAltNav
              setScene={setScene}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId} 
            />
          );
        }
      } else {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <TopNav
              setScene={setScene}
              searchFilmsResponse={searchFilmsResponse}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              searchType={searchType}
              setSearchType={setSearchType}
              userId={userId} 
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              allResults={allResults}
              setAllResults={setAllResults}
            />
          );
        } else {
          return (
            <AltNav
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId} 
            />
          );
        } 
    }
}
              
    return (
        <VStack marginTop={'-8px'}>
            {renderTopNav(scene)}
            {scene === 'HOME' && (
                <HomeScene
                    setScene={setScene}
                    setSelectedFilm={setSelectedFilm}
                />
            )}
            {scene === 'SEARCH_RESULTS' && (
                <SearchResultsScene
                    // allResults={allResults}
                    results={searchFilmsResponse}
                    setScene={setScene}
                    setSelectedFilm={setSelectedFilm}
                    filterIsLoading={filterIsLoading}
                    setSearchType={setSearchType}
                    searchType={searchType}
                    selectedGenres={selectedGenres}
                    setSelectedGenres={setSelectedGenres}
                />
            )}
        </VStack>
    );
}

export default HomePage;