import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    Box,
    useToast,
    useColorMode,
} from '@chakra-ui/react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import './index.css';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import AboutPage from "./components/AboutPage";
import VerifyPage from './components/VerifyPage';
import VerifyEmailChangePage from './components/VerifyEmailChangePage';
import {logoutApi} from "./api";
import ProfilePage from './components/ProfilePage';
import PlaylistPage from './components/PlaylistPage';
import FilmPage from './components/FilmPage';
import PassResetPage from './components/PassResetPage';
import { useAuth } from './components/AuthContext';

function App() {
  const {loggedIn, userId, setLoggedIn, setUserId} = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const [customSwitchChecked, setCustomSwitchChecked] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const isMobile = useMedia('(max-width: 767px)');


  //Logout Function
   const handleLogout = async () => {
    try{
      await logoutApi();
      setLoggedIn(false);
      localStorage.clear();
      toast({
        title: 'Successfully Logged Out',
        description: "login or create an account again to rate more films",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
    catch (e) {
      console.log('ERROR logging in to account in RegisterPage: ', e);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={
            <HomePage
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
              userId={userId}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />} 
          />
          <Route path="/films/:filmId" element={
            <FilmPage
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
              userId={userId}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />}
          />
          <Route path="/about" element={
            <AboutPage
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />}
          />
          <Route path="/login" element={
            <LoginPage
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
              setUserId={setUserId}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />} 
          />
          <Route path="/verify-email/" element={<VerifyPage />} />
          <Route path="/verify-email-change" element={<VerifyEmailChangePage />} />
          <Route path="/password-reset" element={<PassResetPage />} />

          <Route path="/profile/:userId" element={
            <ProfilePage 
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              loggedInUserId={userId}
            />} 
          />
          <Route path="/profile/:userId/playlists/:playlistId" element={
            <PlaylistPage 
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            handleLogout={handleLogout}
            customSwitchChecked={customSwitchChecked}
            setCustomSwitchChecked={setCustomSwitchChecked}
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
            loggedInUserId={userId}
            />} 
          />


          {/* haven't implemented this route yet */}
          <Route path="/filters/:topcategory?/:genre?" element={
              <HomePage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                handleLogout={handleLogout}
                userId={userId}
                customSwitchChecked={customSwitchChecked}
                setCustomSwitchChecked={setCustomSwitchChecked}
                colorMode={colorMode}
                toggleColorMode={toggleColorMode}
              />}
            />
            {/* haven't implemented this path yet, need to route to searchPage or something instead */}
            <Route path="/search/:usersearchinput" element={
              <HomePage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                handleLogout={handleLogout}
                userId={userId}
                customSwitchChecked={customSwitchChecked}
                setCustomSwitchChecked={setCustomSwitchChecked}
                colorMode={colorMode}
                toggleColorMode={toggleColorMode}
              />}
            />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
